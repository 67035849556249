<template>
    <section class="section section-shaped section-zero min-vh-100 d-flex justify-content-center align-items-center">
        <div class="shape shape-style-1 bg-gradient-lighter">
        </div>
        <div class="container pt-lg-md" :key="componentKey">
            <div class="row justify-content-center">
                <div class="col-lg-5" v-if="type_active !== 'not-have-permission'">
                    <div class="card border-0">
                        <div class="card-header bg-white border-0"
                             v-if="['register', 'remember', 'login', 'login-token', 'login-ba'].includes(type_active)">
                            <div class="row align-items-center">
                                <div class="col-8">
                                    <h5 class="mb-0 clean-text-css" v-if="type_active === 'register'">{{$t('ct-register')}}</h5>
                                    <h5 class="mb-0 clean-text-css" v-else-if="type_active === 'login'">{{$t('ct-sign-in')}}</h5>
                                    <h5 class="mb-0 clean-text-css" v-else-if="type_active === 'login-token'">{{$t('ct-access-with-token')}}</h5>
                                    <h5 class="mb-0 clean-text-css" v-else-if="type_active === 'remember'">{{$t('ct-remember')}}</h5>
                                    <h5 class="mb-0 clean-text-css" v-else-if="type_active === 'login-ba'">{{$t('ct-access-ba-credentials')}}</h5>
                                </div>
                                <div class="col-4 text-right">
                                    <a href="#" class="btn btn-sm btn-primary" @click.prevent="redirectLink()">{{ $t('ct-home') }}</a>
                                </div>
                            </div>
                        </div>
                        <div class="card-body bg-secondary px-lg-5 py-lg-4">
                            <register :areas_register="areas_register" @change="changeType"
                                      v-if="type_active === 'register'"></register>
                            <login :areas_register="areas_register" @change="changeType"
                                   v-else-if="type_active === 'login'"></login>
                            <login-token @change="changeType"
                                               v-else-if="type_active === 'login-token'"></login-token>
                            <login-builderall @change="changeType"
                                         v-else-if="type_active === 'login-ba'"></login-builderall>
                            <remember-password @change="changeType"
                                               v-else-if="type_active === 'remember'"></remember-password>
                            <not-have-permission @change="changeType" v-else></not-have-permission>
                        </div>
                    </div>
                    <div class="mx-2 mt-2 mb-3 d-flex"
                         v-if="['register', 'remember', 'login', 'login-ba'].includes(type_active) && (auth_types.includes('register') || auth_types.includes('both'))">
                        <div class="text-left flex-grow-1 px-2">
                            <a href="#" class="text-light" @click.prevent="changeType('login')"
                               v-if="['register', 'remember','login-ba'].includes(type_active)">{{ $t('ct-sign-in')}}</a>
                            <a href="#" class="text-light" @click.prevent="changeType('register')"
                               v-else-if="type_active === 'login' && !config.hide_register_btn">{{ $t('ct-register')}}</a>
                        </div>

                        <div class="text-right flex-grow-1 px-2">
                            <a href="#" class="text-light" @click.prevent="changeType('remember')"
                               v-if="type_active !== 'remember'">{{ $t('ct-forgot-password')}}</a>
                        </div>

                    </div>
                </div>

                <div class="col-12 col-lg-6" v-else-if="type_active === 'not-have-permission'">
                    <not-have-permission></not-have-permission>
                </div>
            </div>
        </div>
    </section>
</template>

<script>

    import {mixinRestrictAreaConfig, mixinRestrictAreaForm} from "@/js/mixins/restrictAreaForm";

    export default {
        name: "DefaultPage",
        mixins: [mixinRestrictAreaConfig, mixinRestrictAreaForm],
    }
</script>
